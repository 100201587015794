import React from 'react'
import {
  TextField,
  Box,
  Slider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import DatePicker, { DateObject, getAllDatesInRange } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"


const App = () => {
  const [hideZeros,setHideZeros] = React.useState(true)
  const [maxHours,setMaxHours] = React.useState(200)
  const [range, setRange] = React.useState([
    new DateObject().subtract(4, "days"),
    new DateObject().add(4, "days")
  ])
  const [exclude,setExclude] = React.useState([])
  const [productiveDay,setProductiveDay] = React.useState([5,8])
  const [unproductiveDay,setUnproductiveDay] = React.useState([2,4])
  const [spread,setSpread] = React.useState(17)
  const [relation,setRelation] = React.useState(50)
  const [timeTable,setTimeTable] = React.useState(null)
  const [rows,setRows] = React.useState(null)
  const [csv,setCSV] = React.useState('')
  const [csvWithoutZeros,setCSVWithoutZeros] = React.useState('')

  const _setHideZeros = (e) => setHideZeros(e.target.checked)
  const _setMaxHours = (e) => setMaxHours(e.target.value)
  const _setProductiveDay = (e,newValue) => setProductiveDay(newValue)
  const _setUnproductiveDay = (e,newValue) => setUnproductiveDay(newValue)
  const _setSpread = (e,newValue) => setSpread(newValue)
  const _setRelation = (e,newValue) => setRelation(newValue)

  const _f = (integer) => Math.round(integer * 100) / 100

  const _doGodsWork = () => {
    setTimeTable(null)

    const workingDays = []
    const allDates = getAllDatesInRange(range)
    for (let i = 0; i < allDates.length; i++) {
      const a = allDates[i]
      let keepDate = true
      for (let j = 0; j < exclude.length; j++) {
        let b = exclude[j]
        if (isSameDate(a,b)) {
          keepDate = false
          break;
        }
      }
      if (keepDate) {
        workingDays.push(allDates[i])
      }
    }

    const times = _times(workingDays)
    setTimeTable(times)

    if (times.enough) {
      const shouldHavePercentageOnProducitveDays = relation
      const shouldHavePercentageOnUnproducitveDays = 100 - relation

      const productiveDays = Math.floor(times.days * shouldHavePercentageOnProducitveDays / 100)
      const unproductiveDays = times.days - productiveDays

      const productiveHours = Math.floor(times.hoursToSpread * relation / 100)
      const unproductiveHours = times.hoursToSpread - productiveHours

      const unproductiveSuggestion = _spreadRecursiveley(
        _f(unproductiveDay[0]),
        _f(unproductiveDay[1]),
        _f(unproductiveHours),
        times.days,
        [...Array(times.days)].map((item,i) => _f(0))
      )

      const productiveSuggestion = _spreadRecursiveley(
        _f(productiveDay[0]),
        _f(productiveDay[1]),
        _f(productiveHours+unproductiveSuggestion.hoursLeft),
        times.days,
        unproductiveSuggestion.schedule
      )

      const rows = []
      for (let i = 0; i < productiveSuggestion.schedule.length; i++) {
        rows.push(createData(workingDays[i],productiveSuggestion.schedule[i]))
      }
      setRows(rows)
      setCSV(_generateCSV(productiveSuggestion.schedule,workingDays))
      setCSVWithoutZeros(_generateCSV(productiveSuggestion.schedule,workingDays,true))
    }
  }

  const _spreadRecursiveley = (min,max,hours,days,schedule) => {
    let randH = Math.random() * (max - min) + min;
    const randD = Math.floor(Math.random() * schedule.length)

    if (schedule[randD] + randH > max) {
      hours = hours - (max - schedule[randD])
      schedule[randD] = max
    } else {
      schedule[randD] = schedule[randD] + randH
      hours -= randH
    }

    if (_isMaxAtEveryDay(schedule,max,days)) {
      return { schedule,hoursLeft:hours }
    }
    if (hours >= 0) {
      _spreadRecursiveley(min,max,hours,days,schedule)
    }
    return { schedule, hoursLeft:0 }
  }

  const _isMaxAtEveryDay = (schedule,max,days) => {
    return schedule.filter(day => day == max).length === days
  }

  const isSameDate = (a,b) => {
    return a.day === b.day && a.month.number === b.month.number && a.year === b.year
  }

  const _times = (workingDays) => {
   const maxHoursPerDay = productiveDay[1]
   const days = workingDays.length
   const hoursToSpread = maxHours * spread / 100
   const averagePerDay = hoursToSpread / maxHoursPerDay

   return {
    days,
    hoursToSpread,
    averagePerDay,
    enough: hoursToSpread / maxHoursPerDay <= days
   }
  }

  const _generateCSV = (schedule,workingDays,hideZeros = false) => {
    var delimiter = ','
    var csv = 'Name,Title\n';
    for (let i = 0; i < schedule.length; i++) {
      let date = workingDays[i]
      date = date.day+'.'+date.month.number+'.'+date.year
      if (!(schedule[i] === 0 && hideZeros)) {
        csv += date + delimiter + schedule[i].toFixed(3) + "\n"
      }
    }
    return csv

  }

  const isZeroDay = (date) => {
    return date === _f(0).toFixed(3)
  }

  const _printCSV = () => {
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(hideZeros?csvWithoutZeros:csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'schedule'+new Date().getTime()+'.csv';
    hiddenElement.click();
  }

  const createData = (date,hours) => {
    date = date.day+'.'+date.month.number+'.'+date.year
    return { date,hours:hours.toFixed(3) }
  }

  const _getTotal = () => {
    let totalSpreaded = _f(0)
    rows.map(entry => {
      totalSpreaded +=parseFloat(entry.hours)
    })

    return totalSpreaded.toFixed(0)
  }

  const spacerStylers = { marginBottom:5,marginTop:5 }

  return (
    <Box
     component="form"
     style={{
       display:'flex',
       flexDirection:'column',
       justifyContent:'center',
       alignItems:'center',
       maxWidth:500,
       margin:'0 auto',
       minHeight:'100vh'
     }}
     noValidate
     autoComplete="off"
     >
      <TextField label="Maximale Stunden" variant="outlined" value={maxHours} onChange={_setMaxHours}/>
      <div style={spacerStylers} />
      <Typography id="spread" gutterBottom>
       {maxHours * spread / 100} von {maxHours} verteilen ({spread}%)
      </Typography>
      <Slider
        value={spread}
        onChange={_setSpread}
        valueLabelDisplay="auto"
        min={0}
        max={100}
        aria-labelledby="spread"
      />
      <div style={spacerStylers} />
      <Typography id="productive-days" gutterBottom>
        Produktiver Tag | {productiveDay[0]} - {productiveDay[1]} Stunden
      </Typography>
      <Slider
        value={productiveDay}
        onChange={_setProductiveDay}
        valueLabelDisplay="auto"
        min={1}
        max={12}
        aria-labelledby="productive-days"
      />
      <div style={spacerStylers} />
      <Typography id="unproductive-days" gutterBottom>
        Unproduktiver Tag | {unproductiveDay[0]} - {unproductiveDay[1]} Stunden
      </Typography>
      <Slider
        value={unproductiveDay}
        onChange={_setUnproductiveDay}
        valueLabelDisplay="auto"
        min={1}
        max={8}
        aria-labelledby="unproductive-days"
      />
      <div style={spacerStylers} />
      <Typography id="relation" gutterBottom>
        davon {relation}% produktive Tage (wenn möglich)
      </Typography>
      <Slider
        value={relation}
        onChange={_setRelation}
        valueLabelDisplay="auto"
        min={1}
        step={1}
        max={100}
        aria-labelledby="relation"
      />
      <div style={spacerStylers} />
      <Typography id="range" gutterBottom>
        Zeitspanne über die verteilt wird
      </Typography>
      <DatePicker
        aria-labelledby="range"
        value={range}
        onChange={setRange}
        range
        plugins={[
          <DatePanel />
        ]}
      />
      <div style={spacerStylers} />
      <Typography id="exclude" gutterBottom>
        Tage die ausgeschlossen werden sollen
      </Typography>
      <DatePicker
        aria-labelledby="exclude"
        value={exclude}
        onChange={setExclude}
        multiple
        plugins={[
          <DatePanel />
        ]}
      />
      <div style={spacerStylers} />
      <Button onClick={_doGodsWork}>Do Gods Work</Button>
      {timeTable && !timeTable.enough && <Alert severity="error">Zeitspanne reicht nicht aus. Prozente oder Stunden verkleinern oder Range erhöhen</Alert>}

      {rows &&
       <>

       <FormGroup>
         <FormControlLabel control={<Checkbox checked={hideZeros} onChange={_setHideZeros} />} label="Tage ohne Stunden ausblenden" />
       </FormGroup>

        <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell align="right">Stunden</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
            if (hideZeros && isZeroDay(row.hours)) return null
            return <TableRow
                key={row.date}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.date}
                </TableCell>
                <TableCell align="right">{parseInt(Math.round(row.hours))}</TableCell>
              </TableRow>
            })}
            <TableRow
                key={"total"}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">

                </TableCell>
                <TableCell align="right" style={{fontWeight:'bold'}}>{_getTotal()} / {parseInt(timeTable.hoursToSpread)}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
        </TableContainer>

       </>
      }

      {csv && csv !== '' && <Button onClick={_printCSV}> CSV </Button>}

    </Box>
  )
}



export default App;
